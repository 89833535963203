import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { memo } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { getDayObjectsBetweenDates } from "shared/libs/hooks/getHeadersForSchedule";
import { IScheduleUser } from "entities/schedule/model/schedule";
import { NoRowsOverlay } from "../ScheduleSearch/NoRowsOverlay";
import ScheduleRowView from "./ScheduleRowView";
import { useAppSelector } from "shared/libs/redux/useAppSelector";
import Pagination from "@mui/material/Pagination";
import { LinearProgress, TableFooter } from "@mui/material";
import { getPeopleWorkingOnDay } from "shared/libs/hooks/getPeopleWorkingOnDay";

interface UserTimelineTableProps {
  endDate: Dayjs | null;
  startDate: Dayjs | null;
  IsSearchComponent: boolean;
  users: IScheduleUser[] | null;
  loading: Boolean;
  setWorkingNow?: React.Dispatch<React.SetStateAction<number | null>>;
  setWorkingToday?: React.Dispatch<React.SetStateAction<number | null>>;
}

export const UserTimelineTable = memo(
  ({
    startDate,
    endDate,
    IsSearchComponent,
    users,
    loading,
    setWorkingNow,
    setWorkingToday,
  }: UserTimelineTableProps) => {
    const dates = getDayObjectsBetweenDates(
      startDate as Dayjs,
      endDate as Dayjs
    );

    const [sortedData, setSortedData] = useState(users);
    const [page, setPage] = React.useState(1);
    const [searchQuery, setSearchQuery] = React.useState("");

    useEffect(() => {
      setSortedData(users);
    }, [users]);
    useEffect(() => {
      setPage(1);
      setSortedData(
        users?.filter((user: IScheduleUser) =>
          user.user.fullName
            .toLocaleLowerCase()
            .includes(searchQuery.toLocaleLowerCase())
        ) || []
      );
    }, [searchQuery]);

    const currentUser = useAppSelector((state) => state.user);

    const getUsersWorkingNow = () => {
      let count = 0;
      if (users) {
        users.filter((user) => {
          const timelineItem = user.timeline.find((item) =>
            dayjs(item.date).isSame(dayjs(), "day")
          );

          timelineItem &&
            timelineItem.ranges.forEach((item) => {
              if (
                dayjs()
                  .utc()
                  .add(3, "hour")
                  .isBetween(item.startDate, item.endDate, "minute")
              ) {
                count++;
              }
              setWorkingNow && setWorkingNow(count);
            });
        });
        return count;
      }
    };

    useEffect(() => {
      getUsersWorkingNow();

      setWorkingToday &&
        setWorkingToday(
          getPeopleWorkingOnDay(dayjs().format("YYYY-MM-DD"), users)
        );
    }, [users]);

    const pageCount = 22;

    return (
      <>
        {loading && !IsSearchComponent ? (
          <LinearProgress sx={{}} />
        ) : (
          <Table
            size="small"
            sx={{
              ".MuiTableCell-root ": {
                border: (theme) =>
                  theme.palette.mode === "dark"
                    ? "1px  solid #4A4A4A"
                    : "1px solid rgba(224, 224, 224, 1)",

                p: 0.5,
              },

              bgcolor: "background.paper",
              display: "table-row-group",
            }}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: 268,
                    textAlign: "center",
                    zIndex: 30,
                    position: "sticky",
                    left: 0,
                    bgcolor: "background.default",
                    minWidth: 240,
                  }}
                >
                  {IsSearchComponent && users ? (
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {users?.some((user: IScheduleUser) =>
                        user.user.userId.includes(currentUser.userId)
                      ) ? (
                        <>
                          <EventNoteIcon fontSize="small" />
                          <Typography width={95}>Мой планер</Typography>
                        </>
                      ) : (
                        <>
                          <AccountCircleIcon fontSize="small" />
                          <Typography display="flex" width={95}>
                            ФИО
                          </Typography>
                        </>
                      )}
                    </Stack>
                  ) : (
                    <TextField
                      value={searchQuery}
                      onChange={(event) => setSearchQuery(event.target.value)}
                      size="small"
                      variant="outlined"
                      fullWidth
                      placeholder="ФИО"
                      sx={{
                        ".MuiOutlinedInput-root": {
                          height: "24px",
                          fontSize: "0.875rem",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <SearchIcon
                            sx={{
                              mr: 1,
                              color: "#898989",
                              fontSize: "18px",
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                </TableCell>

                {getDayObjectsBetweenDates(
                  startDate as Dayjs,
                  endDate as Dayjs
                ).map((item) => (
                  <TableCell
                    sx={{
                      backgroundColor: (theme) =>
                        dayjs(item.date).day() === 6 ||
                        dayjs(item.date).day() === 0
                          ? "background.default"
                          : dayjs(item.date).isSame(dayjs(), "day")
                          ? theme.palette.mode === "dark"
                            ? "#32373B"
                            : "#FCF8EB"
                          : "background.paper",

                      zIndex: 20,
                      height: 32,
                      minWidth: 99,
                      maxWidth: 99,
                    }}
                    key={JSON.stringify(item)}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={0.5}
                      >
                        <Typography
                          color="text.secondary"
                          fontWeight={"bold"}
                          sx={{ fontSize: "0.875rem" }}
                        >
                          {dayjs(item.date).format("DD")}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {Object.values(item.dayOfWeek)}
                        </Typography>
                      </Stack>

                      {!IsSearchComponent && (
                        <Chip
                          sx={{
                            borderRadius: "6px",
                            ".MuiChip-label ": {
                              px: "8px",
                            },
                          }}
                          variant="outlined"
                          size="small"
                          label={getPeopleWorkingOnDay(item.date, users)}
                        />
                      )}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <>
              <>
                {sortedData
                  ?.slice(
                    page === 1 ? 0 : page * pageCount - pageCount,
                    page * pageCount
                  )
                  .map((user: IScheduleUser) => (
                    <ScheduleRowView
                      key={user.user.userId}
                      dates={dates}
                      user={user}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ))}
              </>

              {sortedData?.length === 0 && (
                <NoRowsOverlay
                  bottomOvarlayText={
                    "Нет результатов, соответствующих вашему запросу"
                  }
                  position={"absolute"}
                  left={"36%"}
                  top={"120px"}
                />
              )}
            </>
          </Table>
        )}
        {!loading &&
        !IsSearchComponent &&
        sortedData &&
        sortedData?.length > pageCount ? (
          <Pagination
            size="small"
            sx={{
              bgcolor: "background.paper",
              width: "auto",
              p: 1,
              mt: 1,
              position: "sticky",
              left: 0,
            }}
            key="pagination"
            shape="rounded"
            count={Math.ceil(
              sortedData?.length ? sortedData?.length / pageCount : 1
            )}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        ) : undefined}
      </>
    );
  }
);
