import { memo } from "react";
import Box from "@mui/material/Box";
import { TimeTrackItem } from "./TimeTrackItem";
import { ITimesheetRecord } from "entities/record/model";
type TimeTrackProps = {
  data: ITimesheetRecord[];
};
export const TimeTrack = memo(({ data }: TimeTrackProps) => {
  // [record, record] -> [[record, record], [record], [record, record, record]]
  function prepareData() {
    const groupedRecords: {
      [key: string]: ITimesheetRecord[];
    } = {};
    data.forEach((record) => {
      function getStartDateWithoutTime(dateString: string): string {
        return dateString.split("T")[0];
      }
      const startDateWithoutTime = getStartDateWithoutTime(
        `${record.startDate}`
      );
      if (groupedRecords[startDateWithoutTime]) {
        groupedRecords[startDateWithoutTime].push(record);
      } else {
        groupedRecords[startDateWithoutTime] = [record];
      }
    });
    return Object.values(groupedRecords);
  }
  return (
    <Box>
      {prepareData().map((groupedrecords, index) => (
        <Box
          sx={{ display: { xs: "none", md: "block" } }}
          bgcolor="background.paper"
          mb={1.5}
          pl={0.5}
          key={index}
          borderRadius={2}
        >
          {groupedrecords.map((record) => (
            <TimeTrackItem key={record.id} record={record} />
          ))}
        </Box>
      ))}
      <Box sx={{ display: { sm: "block", md: "none" } }}>
        Для твоего расширения доступна только таблица :C
      </Box>
    </Box>
  );
});
