import { useState, useEffect } from "react";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";
import { usersApi } from "entities/user/api/userService";
import { UsersListTrainingItem } from "./UsersListTrainingItem";

export const UsersListTraining = () => {
  const users = usersApi.useGetAllEnabledUsersQuery();
  const [filterString, setFilterString] = useState("");
  const [data, setData] = useState(users.data);
  const [page, setPage] = useState(1);

  useEffect(() => {
    filterString.length >= 3
      ? setData(
          users.data?.filter((item) =>
            item.fullName?.toLowerCase().includes(filterString.toLowerCase())
          )
        )
      : setData(users.data);
  }, [users.data, filterString]);

  return (
    <Box>
      <Box
        sx={{
          borderRadius: "5px",
          bgcolor: "background.paper",
          p: 1,
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          onChange={(e) => {
            setFilterString(e.target.value);
            if (e.target.value.length >= 3) {
              setData(
                users.data?.filter((item) =>
                  item.fullName
                    ?.toLowerCase()
                    .includes(e.target.value.toLowerCase())
                )
              );
            } else {
              setData(users.data);
            }
          }}
          sx={{ mr: 2, width: { xs: "80px", sm: "auto" } }}
          size="small"
          label="ФИО"
          variant="outlined"
        />
      </Box>
      <List
        dense
        sx={{
          display: data?.length === 0 ? "none" : null,
          p: 1,
          borderRadius: "5px",
          bgcolor: "background.paper",
          maxHeight: "64vh",
          overflow: "auto",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {data?.slice(page === 1 ? 0 : page * 10 - 10, page * 10).map((user) => {
          return <UsersListTrainingItem key={user.userId} user={user} />;
        })}
      </List>
      <Pagination
        size="small"
        sx={{
          bgcolor: "background.paper",
          width: "auto",
          p: 1,
          mt: 2,
          borderRadius: "5px",
        }}
        key="pagination"
        count={Math.ceil(users.data?.length ? users.data?.length / 10 : 1)}
        page={page}
        onChange={(_, value) => setPage(value)}
      />
    </Box>
  );
};
