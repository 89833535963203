import React from "react";
import dayjs, { Dayjs } from "dayjs";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { hoursConverter } from "shared/libs/hooks/hoursConverter";
import { scheduleApi } from "entities/schedule/api/scheduleService";

interface SelectedCellsPopupProps {
  selectedCell: string;
  onClose: () => void;
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  setSelectedCell: React.Dispatch<React.SetStateAction<string | undefined>>;
  buttonText: string;
  setButtonText: React.Dispatch<React.SetStateAction<string>>;
  buttonDisabled: boolean;
  setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  timeline: string;
  setTimeline: React.Dispatch<React.SetStateAction<string>>;
  startTime: Dayjs | null;
  setStartTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  endTime: Dayjs | null;
  setEndTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
}

export const SelectedCellsPopup = ({
  selectedCell,
  onClose,
  anchorEl,
  setSelectedCell,
  buttonText,
  setButtonText,
  buttonDisabled,
  setButtonDisabled,
  timeline,
  setTimeline,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  setAnchorEl,
}: SelectedCellsPopupProps) => {
  const availablePattern = ["2/2", "5/2", "7/0", "4/3", "2/2/3"];
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCell(undefined);
    onClose();
    setButtonText("Выберите паттерн и время");
    setTimeline("");
    setButtonDisabled(true);
  };

  const handleChangeTimeline = (event: SelectChangeEvent) => {
    const selectedTimline = event.target.value as string;
    setTimeline(selectedTimline);
  };

  const [scheduleCreate] = scheduleApi.useCreatePatternToUserMutation();

  const handleSubmit = () => {
    scheduleCreate({
      userId: selectedCell.slice(0, 36),
      pattern: timeline,
      startDate: startTime?.format() || dayjs.utc().format("DD/MM/YYYY"),
      endDate: hoursConverter(
        startTime || dayjs.utc(),
        endTime || dayjs.utc()
      ).format(),
    });
    setAnchorEl(null);
    setSelectedCell(undefined);
    onClose();
    setButtonText("Выберите паттерн и время");
    setTimeline("");
    setButtonDisabled(true);
  };

  // const scheduleCreate = scheduleApi.useCreatePatternToUserMutation({
  //   userId: selectedCell.slice(0, 36),
  //   startTime: startTime?.subtract(3, "hour"),
  //   endTime: hoursConverter(
  //     startTime?.subtract(3, "hour") || dayjs.utc(),
  //     endTime?.subtract(3, "hour") || dayjs.utc()
  //   ),
  //   pattern: timeline,
  // });

  React.useEffect(() => {
    if (
      timeline &&
      endTime &&
      startTime &&
      ((endTime.format("HH:mm") !== "00:00" &&
        startTime.format("HH:mm") !== "00:00") ||
        (endTime.format("HH:mm") === "00:00" &&
          startTime.format("HH:mm") !== "00:00") ||
        (endTime.format("HH:mm") !== "00:00" &&
          startTime.format("HH:mm") === "00:00"))
    ) {
      setButtonDisabled(false);
      setButtonText(
        `Заполнить паттерном  ${timeline} ${startTime.format(
          "HH:mm"
        )} — ${endTime.format("HH:mm")} c ${dayjs(selectedCell.slice(37, 47))
          .locale("ru")
          .format("DD.MM.YY")} до конца месяца/∞`
      );
      if (startTime?.format() === endTime?.format() && timeline) {
        setButtonDisabled(true);
        setButtonText("Выберите разное время");
      }
    } else {
      setButtonDisabled(true);
      setButtonText("Выберите паттерн и время");
    }
  }, [
    timeline,
    startTime,
    endTime,
    setButtonDisabled,
    setButtonText,
    selectedCell,
  ]);

  return (
    <Popover
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ mb: 4, pb: 2 }}
      id={id}
      open={open}
      anchorEl={anchorEl}
    >
      <Paper sx={{ p: 1 }}>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="caption">
            {dayjs(selectedCell.slice(37, 47)).locale("ru").format("LL")}
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Паттерн</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={timeline}
              label="Время"
              onChange={handleChangeTimeline}
            >
              {availablePattern.map((graphPattern) => (
                <MenuItem key={graphPattern} value={graphPattern}>
                  {graphPattern}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            sx={{ bgcolor: "background.papper" }}
            spacing={1}
          >
            <TimePicker
              value={startTime}
              slotProps={{
                textField: { size: "small" },
                openPickerButton: { size: "small" },
              }}
              label="Начало"
              sx={{ width: 117 }}
              onChange={(value) => {
                setStartTime(value);
              }}
            />

            <TimePicker
              sx={{ width: 117 }}
              slotProps={{
                textField: { size: "small" },
                openPickerButton: { size: "small" },
              }}
              label="Конец"
              value={endTime}
              onChange={(value) => {
                setEndTime(value);
              }}
            />
          </Stack>
          <Button
            variant="contained"
            color={"success"}
            size="small"
            disabled={buttonDisabled}
            onClick={handleSubmit}
            sx={{ width: "242px" }}
          >
            {buttonText}
          </Button>
          {/* <ScheduleCreateRecord user={currentUser}></ScheduleCreateRecord> */}
        </Stack>
      </Paper>
    </Popover>
  );
};
