import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TelegramIcon from "@mui/icons-material/Telegram";
import GroupIcon from "@mui/icons-material/Group";
import NumbersIcon from "@mui/icons-material/Numbers";
import { useEditUserMutation } from "entities/user/api/userService";
import { IUsersState } from "entities/user/model";
import { CircularProgress } from "@mui/material";

export const UsersListTrainingItem = ({ user }: { user: IUsersState }) => {
  const [editUser, result] = useEditUserMutation();
  function handleSubmit(
    event: React.FormEvent<HTMLFormElement>,
    user: IUsersState
  ) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    editUser({
      ...user,
      trainingGroup: `${formData.get("group")}`,
      trainingLevel: +`${formData.get("level")}`,
      tgLink: `${formData.get("link")}`,
    });
  }
  return (
    <ListItem
      sx={{
        px: 1,
        borderRadius: "5px",
        mb: 1,
        ":last-child": { mb: 0 },
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#404040" : "#e0e0e0",
      }}
      key={user.userId}
      disableGutters
    >
      <Box
        onSubmit={(event) => handleSubmit(event, user)}
        component="form"
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width="30%">{user.fullName}</Box>
        <TextField
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <NumbersIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{ max: "20" }}
          sx={{ width: 150 }}
          name="level"
          type="number"
          key={user.trainingLevel}
          defaultValue={user.trainingLevel}
        />
        <TextField
          size="small"
          sx={{ width: 150 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GroupIcon />
              </InputAdornment>
            ),
          }}
          name="group"
          type="text"
          key={`group${user.trainingGroup}`}
          defaultValue={user.trainingGroup}
        />
        <TextField
          size="small"
          sx={{ width: 150 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TelegramIcon />
              </InputAdornment>
            ),
          }}
          name="link"
          type="text"
          key={`link${user.tgLink}`}
          defaultValue={user.tgLink}
        />
        <IconButton key={user.userId} color="success" type="submit">
          {result.isLoading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            <CheckIcon />
          )}
        </IconButton>
      </Box>
    </ListItem>
  );
};
