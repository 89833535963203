import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useOutside } from "shared/libs/hooks/useOutside";
import { recordsApi } from "entities/record/api/recordService";
import { useAppSelector } from "shared/libs/redux";
import dayjs, { Dayjs } from "dayjs";

export const RecordCreateButton = () => {
  const user = useAppSelector((state) => state.user);
  const { ref, isShow, setIsShow } = useOutside(false);
  const [create] = recordsApi.useCreateRecordMutation();

  const createRecord = (date: Dayjs) => {
    create({
      userId: user.userId,
      positionId: user.positionId,
      updated: `${dayjs.utc()}`,
      startDate: date,
      endDate: date.add(15, "minutes"),
      isConfirmed: false,
      isDeleted: false,
      is15x: false,
      is20x: false,
      comment: "",
    });
  };
  return (
    <Box ref={ref}>
      <Fab
        onClick={() => setIsShow(!isShow)}
        color="success"
        sx={{ position: "fixed", bottom: 16, left: 16 }}
      >
        {isShow ? <EditIcon /> : <AddIcon />}
      </Fab>

      {isShow && (
        <DateCalendar
          value={dayjs.utc()}
          onChange={(e) => {
            e && createRecord(e);
            setIsShow(false);
          }}
          sx={{
            zIndex: 2,
            width: 300,
            borderRadius: 1,
            bgcolor: "background.paper",
            position: "fixed",
            bottom: 70,
            left: 70,
          }}
        />
      )}
    </Box>
  );
};
