import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { usersApi } from "entities/user/api/userService";
import { DateRangePicker } from "shared/ui";
import { ScheduleSearch, ScheduleTable } from "entities/schedule/ui";
import { ScheduleFAQ } from "entities/schedule/ui/ScheduleFAQ/ScheduleFAQ";
import { scheduleApi } from "entities/schedule/api/scheduleService";
import { UserTimelineTable } from "entities/schedule/ui/ScheduleTable/UserTimelineTable";
import { IUsersState } from "entities/user/model/user";
import { useAppSelector } from "shared/libs/redux/useAppSelector";
import { ScheduleVisibleSwitch } from "entities/theme/ui";
import { ScheduleFIleUpload } from "entities/schedule/ui/ScheduleFIleUpload/ScheduleFIleUpload";

const Schedule = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs.utc());
  const [endDate, setEndDate] = useState<Dayjs | null>(
    dayjs.utc().add(14, "day")
  );

  const [department, setDepartment] = useState(0);
  const [scheduleUser, setScheduleUser] = useState<IUsersState | null>(null);

  const currentUser = useAppSelector((state) => state.user);
  const visible = useAppSelector((state) => state.theme);

  const users = scheduleApi.useGetUsersInSearchQuery(
    {
      name: scheduleUser?.fullName || currentUser.fullName,
      startDate: `${startDate?.format("YYYY-MM-DD")}`,
      endDate: `${dayjs.utc(endDate).add(1, "day").format("YYYY-MM-DD")}`,
    },
    {
      refetchOnFocus: visible.scheduleVisible,
      pollingInterval: visible.scheduleVisible ? 60000 : 0,
    }
  );

  const departments = scheduleApi.useGetAllDepartmentsQuery();

  if (departments.isLoading || users.isLoading) {
    return (
      <div>
        <CircularProgress
          sx={{
            position: "absolute",
            top: "33%",
            left: "50%",
            ml: "-3rem",
            mt: "-3rem",
          }}
          size={"6rem"}
        />
      </div>
    );
  }
  if (departments.error || users.error) {
    return <div>{JSON.stringify(departments.error || users.error)}</div>;
  }
  return (
    <>
      <Stack
        sx={{ bgcolor: "background.paper", p: 1, borderRadius: "5px", mb: 1 }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <DateRangePicker
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
        <Divider orientation="vertical" flexItem></Divider>

        <Stack
          width={"100%"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <ScheduleSearch
            scheduleUser={scheduleUser}
            setScheduleUser={setScheduleUser}
            endDate={endDate}
            startDate={startDate}
          />
          <ScheduleFIleUpload currentUser={currentUser} />
          <ScheduleVisibleSwitch
            currentUser={currentUser}
            users={users.data?.map((item) => item)}
          />

          <ScheduleFAQ />
        </Stack>
      </Stack>
      {users.data?.map((item) => (
        <Box key={item.user.userId}>
          {visible.scheduleVisible ||
          item.user.fullName !== currentUser.fullName ? (
            <Box
              sx={{
                mb: 1,
                p: 1,
                bgcolor: "background.paper",
                borderRadius: "6px",
              }}
            >
              <Box
                sx={{
                  overflow: "auto",
                }}
              >
                <UserTimelineTable
                  loading={users.isLoading || users.isUninitialized}
                  endDate={endDate}
                  startDate={startDate}
                  IsSearchComponent={true}
                  users={users.data}
                ></UserTimelineTable>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      ))}
      <ScheduleTable
        department={department}
        setDepartment={setDepartment}
        endDate={endDate}
        startDate={startDate}
        data={departments.data || []}
      ></ScheduleTable>
    </>
  );
};

export default Schedule;
