import { baseApi } from "shared/api";
import { IAllDepartments, IScheduleUser } from "../model/schedule";

export const scheduleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDepartments: builder.query<Array<IAllDepartments>, void>({
      query: () => ({
        url: `/schedule`,
        method: "GET",
      }),
      providesTags: ["Schedule"],
    }),
    getSchedule: builder.query<
      Array<IScheduleUser>,
      { positionId: number; startDate: string; endDate: string }
    >({
      query: ({ positionId, startDate, endDate }) => ({
        url: `/schedule`,
        method: "POST",
        body: { positionId, startDate, endDate },
      }),
      providesTags: ["Schedule", "Record"],
    }),

    createPatternToUser: builder.mutation<
      void,
      {
        userId: string;
        pattern: string;
        startDate: string;
        endDate: string;
      }
    >({
      query: (schedule) => ({
        url: `/schedule/create`,
        method: "POST",
        body: {
          ...schedule,
        },
      }),
      invalidatesTags: ["Schedule", "Record"],
    }),
    getUsersInSearch: builder.query<
      Array<IScheduleUser>,
      { name: string; startDate: string; endDate: string }
    >({
      query: ({ name, startDate, endDate }) => ({
        url: `/schedule/search`,
        method: "POST",
        body: { name, startDate, endDate },
      }),
      providesTags: ["Schedule", "Record"],
    }),
  }),
});

export const { useGetScheduleQuery } = scheduleApi;
