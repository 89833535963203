import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { trainingApi } from "entities/training/api/trainingService";
import dayjs, { Dayjs } from "dayjs";

export const TrainingAccounting = ({
  startDate,
  endDate,
}: {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}) => {
  const { data, error } = trainingApi.useGetTrainingsAccountingQuery({
    startDate: `${startDate?.format("YYYY-MM-DD")}`,
    endDate: `${endDate?.add(1, "day").format("YYYY-MM-DD")}`,
  });

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }
  return (
    <Box p={1} borderRadius="5px" bgcolor={"background.paper"}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="accounting table">
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell align="right">Тренер</TableCell>
              <TableCell align="right">Название</TableCell>
              <TableCell align="right">tg login</TableCell>
              <TableCell align="right">ФИО оператора</TableCell>
              <TableCell align="right">Оценка</TableCell>
              <TableCell align="right">Чек</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!data?.length && (
              <TableRow
                key="nodata"
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  Нет данных
                </TableCell>
              </TableRow>
            )}
            {data?.map((row) => (
              <TableRow
                key={`${row.participantFullName}${row.tgLink}`}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  bgcolor: (theme) =>
                    row.feedback
                      ? theme.palette.mode === "dark"
                        ? "#2e7d327f"
                        : "#66bb6a7f"
                      : "",
                }}
              >
                <TableCell component="th" scope="row">
                  {dayjs.utc(row.trainingDate).format("DD.MM.YYYY HH:mm")}
                </TableCell>
                <TableCell align="right">{row.mentorName}</TableCell>
                <TableCell align="right">{row.trainingName}</TableCell>
                <TableCell align="right">{row.tgLink}</TableCell>
                <TableCell align="right">{row.participantFullName}</TableCell>
                <TableCell align="right">{row.score}</TableCell>
                <TableCell>{row.feedback ? "✅" : "❌"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
