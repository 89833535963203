import React, { ChangeEvent, memo, useRef, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { DatePicker } from "@mui/x-date-pickers";
import { TimeTrackSlider } from "./TimeTrackSlider";
import { PositionSelect } from "..";
import dayjs, { Dayjs } from "dayjs";
import { recordsApi } from "entities/record/api/recordService";
import { useDebouncedCallback } from "use-debounce";
import { ITimesheetRecord } from "entities/record/model";

type TimeTrackItemProps = {
  record: ITimesheetRecord;
};
export const TimeTrackItem = memo(({ record }: TimeTrackItemProps) => {
  const [updateRecord] = recordsApi.useUpdateRecordMutation();
  const [deleteRecord] = recordsApi.useDeleteRecordMutation();
  const [createRecord] = recordsApi.useCreateRecordMutation();
  const [rate, setRate] = useState<string | null>(
    record.is15x ? "1.5x" : record.is20x ? "2x" : null
  );
  const [position, setPosition] = useState(record.positionId);
  const inputRef = useRef<HTMLInputElement>();
  const isEditable = () => {
    return (
      `${record.startDate}`.split("T")[0].split("-")[2] ===
      `${record.endDate}`.split("T")[0].split("-")[2]
    );
  };
  const handlePositionChange = (newPosition: number) => {
    !record.isConfirmed && isEditable() && setPosition(newPosition);
    !record.isConfirmed &&
      isEditable() &&
      updateRecord({ ...record, positionId: newPosition });
  };
  const handleCommentChange = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      !record.isConfirmed &&
        isEditable() &&
        updateRecord({ ...record, comment: e.target.value });
    },
    400
  );

  const handleDateChange = (e: Dayjs) => {
    !record.isConfirmed &&
      isEditable() &&
      updateRecord({
        ...record,
        startDate: e.format(
          `YYYY-MM-DDT${dayjs.utc(record.startDate).format("HH:mm")}`
        ),
        endDate: e.format(
          `YYYY-MM-DDT${dayjs.utc(record.endDate).format("HH:mm")}`
        ),
      });
  };
  const handleRate = (
    event: React.MouseEvent<HTMLElement>,
    newRate: string | null
  ) => {
    !record.isConfirmed &&
      isEditable() &&
      updateRecord({
        ...record,
        is15x: newRate === "1.5x" && true,
        is20x: newRate === "2x" && true,
      });
    !record.isConfirmed && isEditable() && setRate(newRate);
  };

  return (
    <Stack
      position="relative"
      height={50}
      alignItems="center"
      bgcolor={(theme) => (theme.palette.mode === "dark" ? "#272727" : "#fff")}
      textAlign="center"
      fontSize="1.05rem"
      fontWeight="600"
      color={(theme) => (theme.palette.mode === "dark" ? "#fff" : "#272727")}
      gap={1}
      direction="row"
      borderRadius={2}
      p={0.5}
      ml={-0.5}
    >
      {/* {!isEditable() && (
        <Box
          borderRadius={2}
          justifyContent="center"
          width="100%"
          height="100%"
          top="50%"
          left="50%"
          sx={{
            zIndex: 1,
            transform: "translate(-50%, -50%)",
            backdropFilter: "blur(1px)",
            backgroundColor: (theme) =>
              theme.palette.mode === "dark"
                ? "rgb(0 0 0 / 0.34)"
                : "rgb(255 255 255 / 0.34)",
          }}
          display="flex"
          alignItems="center"
          position="absolute"
        >
          Не поддерживаемый формат даты
          <Tooltip title="Это запись с концом смены после 0:00, отредактировать её можно только в таблице">
            <HelpIcon sx={{ ml: 1 }} />
          </Tooltip>
        </Box>
      )} */}
      {isEditable() ? (
        <>
          <Box
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            bgcolor={(theme) =>
              theme.palette.mode === "dark" ? "#121212" : "#f2f2f2"
            }
            borderRadius={1}
            minWidth={70}
          >
            <DatePicker
              disabled={!!record.isConfirmed}
              onChange={(e) => e && handleDateChange(e)}
              sx={{
                fieldset: { border: "0" },
                width: 80,
                textAlign: "center",
                ".MuiInputBase-root": {
                  ".MuiInputAdornment-root": { display: "none" },
                  input: {
                    pl: 1,
                  },
                  px: 0,
                  fontWeight: 900,
                  fontSize: "1.2rem",
                },
              }}
              format="DD.MM"
              slotProps={{
                textField: { size: "small" },
                openPickerButton: { sx: { display: "none" } },
              }}
              value={dayjs.utc(record.startDate)}
            />

            <IconButton
              disabled={!!record.isConfirmed}
              onClick={() =>
                !record.isConfirmed && isEditable() && createRecord(record)
              }
              sx={{
                position: "absolute",
                width: 20,
                height: 20,
                bottom: -3,
                right: -3,
                svg: { fontSize: "1rem" },
              }}
              size="small"
            >
              <AddIcon />
            </IconButton>
          </Box>
          <Box
            p={1}
            px={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            bgcolor={(theme) =>
              theme.palette.mode === "dark" ? "#121212" : "#f2f2f2"
            }
            borderRadius={1}
            sx={{ minWidth: { md: 350, lg: 600 } }}
          >
            <TimeTrackSlider
              hours={inputRef}
              updatePost={updateRecord}
              record={record}
            />
          </Box>
        </>
      ) : (
        <Box
          bgcolor={(theme) =>
            theme.palette.mode === "dark" ? "#121212" : "#f2f2f2"
          }
          p={1}
          borderRadius={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          textAlign="center"
          sx={{ minWidth: { md: 428, lg: 678 } }}
        >
          <Box>
            {`${dayjs
              .utc(record.startDate)
              .locale("ru")
              .format("DD MMMM HH:mm")}   —   ${dayjs
              .utc(record.endDate)
              .locale("ru")
              .format("DD MMMM HH:mm")}`}
          </Box>
          <Box display="flex" alignItems="center">
            <Tooltip title="Это запись с концом смены после 0:00, отредактировать её можно только в таблице">
              <HelpIcon sx={{ ml: 1 }} />
            </Tooltip>
          </Box>
        </Box>
      )}
      <Box
        ref={inputRef}
        border={0}
        disabled
        component="input"
        defaultValue={record.hours}
        fontWeight={900}
        fontSize="1.3rem"
        color={(theme) => (theme.palette.mode === "dark" ? "#fff" : "#272727")}
        display="flex"
        justifyContent="center"
        textAlign="center"
        height="100%"
        bgcolor={(theme) =>
          theme.palette.mode === "dark" ? "#121212" : "#f2f2f2"
        }
        fontFamily="inherit"
        borderRadius={1}
        width={60}
        minWidth={60}
      />
      <Box
        height="100%"
        bgcolor={(theme) =>
          theme.palette.mode === "dark" ? "#121212" : "#f2f2f2"
        }
        borderRadius={1}
        minWidth={87}
      >
        <ToggleButtonGroup
          disabled={!!record.isConfirmed}
          sx={{ height: 42 }}
          size="small"
          value={rate}
          exclusive
          onChange={handleRate}
          aria-label="text rate"
        >
          <ToggleButton
            sx={{
              "&.Mui-selected": {
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "" : "rgba(46, 125, 50, 0.2)",
              },
            }}
            color="success"
            value="1.5x"
            aria-label="left aligned"
          >
            1.5x
          </ToggleButton>
          <ToggleButton
            sx={{
              "&.Mui-selected": {
                bgcolor: (theme) =>
                  theme.palette.mode === "dark" ? "" : "rgba(46, 125, 50, 0.2)",
              },
            }}
            color="success"
            value="2x"
            aria-label="centered"
          >
            2.0x
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        bgcolor={(theme) =>
          theme.palette.mode === "dark" ? "#121212" : "#f2f2f2"
        }
        borderRadius={1}
        width={150}
        minWidth={90}
        sx={{
          ".MuiFormControl-root": {
            mb: "15px",
            mr: "5px",
            ml: "5px",
            label: { display: "none" },
          },
          ".MuiInputBase-root": {
            "&:before": {
              border: 0,
            },
            "&:hover": {
              border: 0,
            },
          },
        }}
      >
        <PositionSelect
          disabled={!!record.isConfirmed}
          position={position}
          setPosition={handlePositionChange}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        bgcolor={(theme) =>
          theme.palette.mode === "dark" ? "#121212" : "#f2f2f2"
        }
        borderRadius={1}
        width={500}
        minWidth={50}
      >
        <TextField
          disabled={!!record.isConfirmed}
          defaultValue={record.comment}
          onChange={handleCommentChange}
          size="small"
          sx={{ fieldset: { border: 0 } }}
          fullWidth
          id="outlined-basic"
          variant="outlined"
        />
      </Box>
      <IconButton
        size="small"
        onClick={() =>
          !record.isConfirmed && isEditable() && deleteRecord(`${record.id}`)
        }
        sx={{
          color: "background.default",
        }}
      >
        <CancelIcon />
      </IconButton>
      <Box display="flex" alignItems="center" justifyContent="center">
        {record.isScheduled && (
          <Tooltip title="Эта смена запланирована через график, изменить её может только СВ">
            <InfoIcon color="primary" />
          </Tooltip>
        )}
      </Box>
    </Stack>
  );
});
