import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import { useAppSelector } from "shared/libs/redux";

export const ScheduleFAQ = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const currentUser = useAppSelector((state) => state.user);

  return (
    <div>
      <Tooltip followCursor title={"Легенда"}>
        <IconButton
          size="large"
          aria-describedby={id}
          type="button"
          onClick={handleClick}
          aria-label="FAQ"
        >
          <HelpIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0.5}
            sx={{ p: 1 }}
          >
            <Stack
              spacing={0.5}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  p: 0.5,

                  borderRadius: "6px",
                }}
              >
                <Chip
                  size="small"
                  label={"12:00—21:00"}
                  sx={{
                    ".MuiChip-label ": {
                      p: "0",
                    },
                    borderRadius: "6px",
                    width: "90px",
                    backdropFilter: "blur(30px)",
                  }}
                />
              </Box>
              <Typography> — Обычный слот</Typography>
            </Stack>
            <Stack
              spacing={0.5}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  p: 0.5,
                  borderRadius: "6px",
                }}
              >
                <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={2}
                  color="warning"
                  sx={{
                    ".MuiBadge-badge": {
                      transform: "scale(1) translate(21%, 27%)",
                      height: "13px",
                      fontSize: "11px",
                      minWidth: "13px",
                      borderRadius: "12px",
                      width: "13px",
                      lineHeight: 1,
                      pl: "7px",
                    },
                  }}
                >
                  <Chip
                    size="small"
                    label={"12:00—21:00"}
                    sx={{
                      ".MuiChip-label ": {
                        p: "0",
                      },
                      borderRadius: "6px",
                      width: "90px",
                      backdropFilter: "blur(30px)",
                    }}
                  />
                </Badge>
              </Box>
              <Typography> — Несколько слотов</Typography>
            </Stack>
            <Stack
              spacing={0.5}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  p: 0.5,

                  borderRadius: "6px",
                }}
              >
                <Chip
                  size="small"
                  label={"12:00—21:00"}
                  sx={{
                    ".MuiChip-label ": {
                      p: "0",
                    },
                    borderRadius: "6px",
                    width: "90px",
                    backdropFilter: "blur(30px)",

                    bgcolor: (theme) =>
                      theme.palette.mode === "dark" ? "#466279" : "#f1e4b0",
                  }}
                />
              </Box>
              <Typography>— Не своя позиция в слоте</Typography>
            </Stack>
            <Stack
              spacing={0.5}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  p: 0.5,
                }}
              >
                <Chip
                  size="small"
                  label={"12:00—21:00"}
                  sx={{
                    ".MuiChip-label ": {
                      p: "0",
                    },
                    borderRadius: "6px",
                    width: "90px",
                    backdropFilter: "blur(30px)",
                    border: (theme) =>
                      theme.palette.mode === "dark"
                        ? "2px solid #ffa726 "
                        : "2px solid #ed6c02",
                  }}
                />
              </Box>
              <Typography> — Незапланированный слот</Typography>
            </Stack>
            <Stack
              spacing={0.5}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  p: 0.5,
                  bgcolor: "RGB(136, 225, 129,0.4)",
                }}
              >
                <Chip
                  size="small"
                  label={"12:00—21:00"}
                  sx={{
                    ".MuiChip-label ": {
                      p: "0",
                    },
                    borderRadius: "6px",
                    width: "90px",
                    backdropFilter: "blur(30px)",
                  }}
                />
              </Box>
              <Typography> — Подтвержденный слот</Typography>
            </Stack>
            {currentUser.role === "SUPERVISOR" && (
              <>
                <Stack
                  spacing={0.5}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      p: 0.5,

                      borderRadius: "6px",
                    }}
                  >
                    <Chip
                      size="small"
                      label={
                        <DeleteOutlineOutlined
                          sx={{ mt: 0.6 }}
                        ></DeleteOutlineOutlined>
                      }
                      sx={{
                        ".MuiChip-label ": {
                          p: "0",
                        },
                        borderRadius: "6px",
                        width: "90px",
                        backdropFilter: "blur(30px)",
                      }}
                    />
                  </Box>
                  <Typography>— Слот, который будет удален</Typography>
                </Stack>
                <Stack
                  spacing={0.5}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      p: 0.5,

                      borderRadius: "6px",
                    }}
                  >
                    <Chip
                      size="small"
                      label={"12:00—21:00"}
                      color="info"
                      variant="outlined"
                      sx={{
                        ".MuiChip-label ": {
                          p: "0",
                        },
                        borderRadius: "6px",
                        width: "90px",
                        backdropFilter: "blur(30px)",
                      }}
                    />
                  </Box>
                  <Typography>
                    — Пример слота, который будет добавлен{" "}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        </Paper>
      </Popover>
    </div>
  );
};
