import { useState } from "react";
import Grid from "@mui/material/Grid";

import {
  TrainingEdit,
  TrainingCard,
  TrainingView,
  TrainingCardAdd,
} from "entities/training/ui";

import { ITraining } from "entities/training/model";
import { TrainingUserAdd } from "entities/training/ui/TrainingModals/TrainingUserAdd";

export const TrainingsGrid = ({
  trainings,
  role,
}: {
  trainings: ITraining[];
  role: string;
}) => {
  const [cardTraining, setCardTraining] = useState<ITraining | null>(null);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [userAddModal, setUserAddModal] = useState(false);
  const handleEditOpen = (training: ITraining) => {
    setCardTraining(training);
    setEditModal(true);
  };
  const handleEditClose = () => {
    setEditModal(false);
    setCardTraining(null);
  };
  const handleViewOpen = (training: ITraining) => {
    setCardTraining(training);
    setViewModal(true);
  };
  const handleViewClose = () => {
    setViewModal(false);
    setCardTraining(null);
  };
  const handleUserAddOpen = (training: ITraining) => {
    setCardTraining(training);
    setUserAddModal(true);
  };
  const handleUserAddClose = () => {
    setUserAddModal(false);
    setCardTraining(null);
  };
  return (
    <>
      <Grid justifyContent="center" container spacing={2}>
        {trainings.map((training) => (
          <Grid key={training.trainingId} item>
            <TrainingCard
              handleEditOpen={handleEditOpen}
              handleViewOpen={handleViewOpen}
              handleUserAddOpen={handleUserAddOpen}
              training={training}
            />
          </Grid>
        ))}
        {(role === "SUPERVISOR" || role === "MENTOR") && (
          <Grid item>
            <TrainingCardAdd />
          </Grid>
        )}
      </Grid>
      {cardTraining && (
        <>
          <TrainingUserAdd
            handleClose={handleUserAddClose}
            open={userAddModal}
            training={cardTraining}
          />
          <TrainingEdit
            training={cardTraining}
            open={editModal}
            handleClose={handleEditClose}
          />
          <TrainingView
            training={cardTraining}
            open={viewModal}
            handleClose={handleViewClose}
          />
        </>
      )}
    </>
  );
};
