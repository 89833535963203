import { Dayjs } from "dayjs";

export const getDayObjectsBetweenDates = (startDate: Dayjs, endDate: Dayjs) => {
  const startDay = startDate.date();
  const startMonth = startDate.month();
  const endDay = endDate.date();
  const endMonth = endDate.month();
  const days = [];

  const weekdaysRussian = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];

  let currentDate = startDate.clone(); // Создаем отдельный экземпляр для итерации по дням

  if (startMonth === endMonth) {
    for (let day = startDay; day <= endDay; day++) {
      const dayOfWeek = weekdaysRussian[currentDate.day()]; // Получаем день недели на русском
      days.push({ date: currentDate.format("YYYY-MM-DD"), dayOfWeek });
      currentDate = currentDate.add(1, "day");
    }
  } else {
    for (let day = startDay; day <= startDate.daysInMonth(); day++) {
      const dayOfWeek = weekdaysRussian[currentDate.day()]; // Получаем день недели на русском
      days.push({ date: currentDate.format("YYYY-MM-DD"), dayOfWeek });
      currentDate = currentDate.add(1, "day"); // Используем отдельный экземпляр для итерации
    }
    currentDate = currentDate.month(endMonth).date(1); // Устанавливаем currentDate на первый день следующего месяца
    for (let day = 1; day <= endDay; day++) {
      const dayOfWeek = weekdaysRussian[currentDate.day()]; // Получаем день недели на русском
      days.push({ date: currentDate.format("YYYY-MM-DD"), dayOfWeek });
      currentDate = currentDate.add(1, "day"); // Используем отдельный экземпляр для итерации
    }
  }

  return days;
};
