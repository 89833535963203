import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { IUsersState } from "entities/user/model";
import { useAppDispatch } from "shared/libs/redux/useAppDispatch";
import { scheduleApi } from "entities/schedule/api/scheduleService";

interface ScheduleFIleUploadProps {
  currentUser: IUsersState;
}
interface ScheduleErrorArrayProps {
  rows: string[];
  usernames: string[];
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ScheduleFIleUpload = ({
  currentUser,
}: ScheduleFIleUploadProps) => {
  const [notification, setNotification] = useState<{
    message: string;
    severity: AlertColor | undefined;
  }>({
    message: "",
    severity: undefined,
  });

  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setdialogOpen] = useState(false);
  const [unloadedUsers, setUnloadedUsers] = useState<ScheduleErrorArrayProps>({
    rows: [],
    usernames: [],
  });

  const [isLoading, setisLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setisLoading(true);
    if (event.target.files) {
      const selectedFile = event.target.files[0];

      const formdata = new FormData();
      formdata.append("file", selectedFile);

      if (selectedFile) {
        const fileName = selectedFile.name;
        if (!fileName.endsWith(".xlsx")) {
          setNotification({
            message: "Пожалуйста, выберите файл с расширением .xlsx",
            severity: "error",
          });
          setOpen(true);
        } else {
          fetch(`${process.env.REACT_APP_BASE_URI}/schedule/import`, {
            method: "POST",
            body: formdata,
            headers: { Authorization: `Bearer ${currentUser.token}` },
          })
            .then((response) => {
              if (response.status !== 200)
                throw new Error("Ошибка при загрузке, проверьте файл");

              return response.json();
            })
            .then((data) => {
              if (data.rows.length === 0 && data.usernames.length === 0)
                setNotification({
                  message: "Файл успешно загружен",
                  severity: "success",
                });
              else {
                setUnloadedUsers(data);

                setdialogOpen(true);
                setNotification({
                  message: "Файл загружен не полностью",
                  severity: "warning",
                });
              }

              dispatch(scheduleApi.util.invalidateTags(["Schedule"]));
            })
            .catch((error) => {
              setNotification({
                message: error.message,
                severity: "error",
              });
            })
            .finally(() => {
              setisLoading(false);
              setOpen(true);
            });
        }
      }
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleCloseDialog = () => {
    setdialogOpen(false);
  };

  return (
    <>
      {currentUser.role === "SUPERVISOR" ? (
        <label>
          <Button
            startIcon={isLoading ? null : <FileUploadIcon />}
            size="small"
            component="span"
            variant="contained"
            sx={{ width: "102px", height: "31px" }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={"1rem"} /> : "Импорт"}
          </Button>

          <VisuallyHiddenInput
            disabled={isLoading}
            type="file"
            accept=".xlsx"
            onChange={handleFileChange}
            value={""}
          />
          {notification && (
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={notification.severity}
                sx={{ width: "100%" }}
              >
                {notification.message}
              </Alert>
            </Snackbar>
          )}
          {dialogOpen && (
            <Dialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Stack direction={"column"} spacing={1}>
                  {unloadedUsers?.rows?.length !== 0 && (
                    <>
                      <Typography variant="h6">
                        {unloadedUsers?.rows?.length === 1
                          ? "Строка с ошибкой"
                          : "Строки с ошибками"}
                      </Typography>
                      <Typography
                        sx={{ wordWrap: "break-word" }}
                        width={"300px"}
                        maxWidth={"500px"}
                      >
                        {unloadedUsers?.rows?.join(", ")}
                      </Typography>
                    </>
                  )}
                  <Divider />
                  <Typography variant="h6">
                    {unloadedUsers?.usernames?.length === 1
                      ? "Пользователя не найдено:"
                      : "Пользователей не найдено:"}
                  </Typography>
                  {unloadedUsers?.usernames?.map(
                    (item: string, index: React.Key | null | undefined) => (
                      <Typography key={index}>{item}</Typography>
                    )
                  )}
                </Stack>
              </DialogContent>
            </Dialog>
          )}
        </label>
      ) : (
        <></>
      )}
    </>
  );
};
