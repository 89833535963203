import { PaletteMode } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

export interface IThemeState {
  mode: PaletteMode;
  variant: "timesheet" | "timetrack";
  scheduleVisible: boolean;
}
const initialState: IThemeState = {
  mode: "dark",
  variant: "timesheet",
  scheduleVisible: true,
};
export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    themeSwitch(state) {
      if (state.mode === "dark") {
        state.mode = "light";
      } else {
        state.mode = "dark";
      }
    },
    timesheetSwitch(state) {
      if (state.variant === "timesheet") {
        state.variant = "timetrack";
      } else {
        state.variant = "timesheet";
      }
    },
    scheduleVisibleSwitch(state) {
      state.scheduleVisible = !state.scheduleVisible;
    },
  },
});

export const { themeSwitch, timesheetSwitch, scheduleVisibleSwitch } =
  themeSlice.actions;
export default themeSlice.reducer;
