import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEditUserMutation } from "entities/user/api/userService";
import { IUsersState } from "entities/user/model";
type UserEditProps = {
  user: IUsersState;
  handleClickOpenEditModal: (user: IUsersState) => void;
  handleClickOpenViewModal: (user: IUsersState) => void;
};
export const UserEdit = ({
  user,
  handleClickOpenEditModal,
  handleClickOpenViewModal,
}: UserEditProps) => {
  const [editUser] = useEditUserMutation();
  const labelId = `checkbox-list-secondary-label-${user.userId}`;

  return (
    <ListItem
      sx={{
        borderRadius: "5px",
        mb: 1,
        ":last-child": { mb: 0 },
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#404040" : "#e0e0e0",
      }}
      key={user.userId}
      secondaryAction={
        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
          <IconButton onClick={() => handleClickOpenEditModal(user)}>
            <SettingsIcon />
          </IconButton>
          <Chip
            sx={{ display: { xs: "none", sm: "inline-flex" } }}
            onClick={() => editUser({ ...user, isDisabled: !user.isDisabled })}
            label={user.isDisabled ? "Отключена" : "Активная"}
            color={user.isDisabled ? "error" : "success"}
          />
        </Box>
      }
      disablePadding
    >
      <ListItemButton
        sx={{
          ":hover": {
            borderRadius: "5px",
          },
        }}
        onClick={() => handleClickOpenViewModal(user)}
      >
        <ListItemAvatar>
          <Avatar sx={{ width: 30, height: 30 }} variant="rounded" />
        </ListItemAvatar>
        <ListItemText
          id={labelId}
          primary={user.fullName}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {user.username}
              </Typography>
              {` — ${user.role}`}
            </React.Fragment>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
