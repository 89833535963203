import { baseApi } from "shared/api";
import { ITrainingAccounting } from "./../model/training";
import { ITraining } from "../model";
export const trainingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTrainings: builder.query<Array<ITraining>, any>({
      query: (data) => ({
        url: `/trainings/get`,
        method: "POST",
        body: { ...data },
      }),
      providesTags: ["Training"],
    }),
    createTraining: builder.mutation<
      ITraining,
      Omit<ITraining, "participants" | "mentor" | "isFinished">
    >({
      query: (training) => ({
        url: `/trainings/create`,
        method: "POST",
        body: {
          ...training,
        },
      }),
      invalidatesTags: ["Training"],
    }),
    editTraining: builder.mutation<
      ITraining,
      Omit<ITraining, "mentor" | "level" | "group" | "trainingId">
    >({
      query: (training) => ({
        url: `/trainings/edit`,
        method: "POST",
        body: {
          ...training,
        },
      }),
      invalidatesTags: ["Training", "Users"],
    }),
    addUserToTraining: builder.mutation<
      null,
      {
        userId: string;
        trainingId: string;
      }
    >({
      query: (training) => ({
        url: `/trainings/add_user`,
        method: "POST",
        body: {
          ...training,
        },
      }),
      invalidatesTags: ["Training"],
    }),
    removeUserFromTraining: builder.mutation<
      null,
      {
        userId: string;
        trainingId: string;
      }
    >({
      query: (training) => ({
        url: `/trainings/remove_user`,
        method: "DELETE",
        body: {
          ...training,
        },
      }),
      invalidatesTags: ["Training"],
    }),
    deleteTraining: builder.mutation<null, ITraining>({
      query: (training) => ({
        url: `/trainings/delete/${training.trainingId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Training"],
    }),
    getTrainingsAccounting: builder.query<
      Array<ITrainingAccounting>,
      {
        startDate: string;
        endDate: string;
      }
    >({
      query: (dates) => ({
        url: `/trainings/accounting/get`,
        method: "POST",
        body: {
          ...dates,
        },
      }),
      providesTags: ["Training"],
    }),
  }),
});

export const {
  useGetTrainingsAccountingQuery,
  useGetTrainingsQuery,
  useCreateTrainingMutation,
  useEditTrainingMutation,
  useDeleteTrainingMutation,
  useAddUserToTrainingMutation,
  useRemoveUserFromTrainingMutation,
} = trainingApi;
