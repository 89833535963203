import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { IScheduleUser } from "entities/schedule/model/schedule";
import { IUsersState } from "entities/user/model";
import { scheduleVisibleSwitch } from "entities/theme/model";
import { useAppDispatch, useAppSelector } from "shared/libs/redux";

interface ScheduleVisibleSwitchProps {
  users: IScheduleUser[] | null | undefined;
  currentUser: IUsersState;
}

export const ScheduleVisibleSwitch = ({
  users,
  currentUser,
}: ScheduleVisibleSwitchProps) => {
  const mode = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser.fullName === users?.[0]?.user?.fullName) setVisible(false);

    return () => {
      setVisible(true);
    };
  }, [currentUser.fullName, users]);

  return (
    <>
      {visible ? (
        <Box padding={3} minWidth={52} minHeight={52}></Box>
      ) : (
        <IconButton
          hidden={true}
          size="large"
          onClick={() => dispatch(scheduleVisibleSwitch())}
        >
          {mode.scheduleVisible ? (
            <Tooltip followCursor title="Не показывать свой планер">
              <EventBusyIcon fontSize="inherit" />
            </Tooltip>
          ) : (
            <Tooltip followCursor title="Показывать свой планер">
              <EventNoteIcon fontSize="inherit" />
            </Tooltip>
          )}
        </IconButton>
      )}
    </>
  );
};
