import { timesheetSwitch } from "entities/theme/model";
import { useAppDispatch, useAppSelector } from "shared/libs/redux";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import TableRowsIcon from "@mui/icons-material/TableRows";

export const TrackSwitch = () => {
  const mode = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  return (
    <IconButton onClick={() => dispatch(timesheetSwitch())} color="inherit">
      {mode.variant === "timesheet" ? <TuneIcon /> : <TableRowsIcon />}
    </IconButton>
  );
};
