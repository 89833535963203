import { useState, useEffect } from "react";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { UserCreate, UserEdit } from "entities/user/ui";
import { DateRangePicker, PositionSelect, RoleSelect, Table } from "shared/ui";
import { useEditUserMutation } from "entities/user/api/userService";
import { recordsApi } from "entities/record/api/recordService";
import { IUsersState } from "entities/user/model";
import dayjs, { Dayjs } from "dayjs";
import { RecordsHours } from "entities/record/ui";

type UsersListProps = {
  usersInPage: IUsersState[];
  allUsers: IUsersState[];
};

export const UsersList = ({ usersInPage, allUsers }: UsersListProps) => {
  const [filterString, setFilterString] = useState("");
  const [cardUser, setCardUser] = useState<IUsersState | null>(null);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [position, setPosition] = useState(cardUser?.positionId || "");
  const [data, setData] = useState(usersInPage);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs.utc().add(-7, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    dayjs.utc().add(7, "day")
  );
  const [editUser] = useEditUserMutation();
  const handleClickOpenViewModal = (user: IUsersState) => {
    setCardUser(user);
    setViewModal(true);
  };
  const handleCloseViewModal = () => {
    setCardUser(null);
    setViewModal(false);
  };
  const handleClickOpenEditModal = (user: IUsersState) => {
    setCardUser(user);
    setEditModal(true);
  };
  const handleCloseEditModal = () => {
    setCardUser(null);
    setEditModal(false);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    cardUser &&
      editUser({
        ...cardUser,
        positionId: position ? +position : cardUser.positionId,
        fullName: `${data.get("fullname")}`,
        updated: `${Date.now()}`,
        contract: `${data.get("contract")}`,
        username: `${data.get("email")}`,
        role: `${data.get("role")}`,
      })
        .then(() => setEditModal(false))
        .catch((error) => console.log(error));
  };
  const userRecord = recordsApi.useGetRecordsByUserQuery({
    startDate: `${startDate?.format("YYYY-MM-DD")}`,
    endDate: `${dayjs.utc(endDate).add(1, "day").format("YYYY-MM-DD")}`,
    userId: cardUser?.userId || "a13a47d9-ac6e-4f57-9be1-e0a96d02c88a",
  });
  useEffect(() => {
    filterString.length >= 3
      ? setData(
          allUsers.filter((item) =>
            item.fullName?.toLowerCase().includes(filterString.toLowerCase())
          )
        )
      : setData(usersInPage);
  }, [usersInPage, allUsers, filterString]);
  return (
    <Box>
      <Box
        sx={{
          borderRadius: "5px",
          bgcolor: "background.paper",
          p: 1,
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          onChange={(e) => {
            setFilterString(e.target.value);
            if (e.target.value.length >= 3) {
              setData(
                allUsers.filter((item) =>
                  item.fullName
                    ?.toLowerCase()
                    .includes(e.target.value.toLowerCase())
                )
              );
            } else {
              setData(usersInPage);
            }
          }}
          sx={{ mr: 2, width: { xs: "80px", sm: "auto" } }}
          size="small"
          label="ФИО"
          variant="outlined"
        />
        <UserCreate />
      </Box>
      <List
        dense
        sx={{
          display: data.length === 0 ? "none" : null,
          p: 1,
          borderRadius: "5px",
          bgcolor: "background.paper",
          maxHeight: "64vh",
          overflow: "auto",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {data.map((user) => {
          return (
            <UserEdit
              handleClickOpenEditModal={handleClickOpenEditModal}
              handleClickOpenViewModal={handleClickOpenViewModal}
              key={user.userId}
              user={user}
            />
          );
        })}
      </List>
      {cardUser && (
        <>
          <Dialog open={editModal} onClose={handleCloseEditModal}>
            <Box component="form" onSubmit={handleSubmit}>
              <DialogTitle sx={{ textAlign: "center", pr: 7, pl: 7 }}>
                Редактирование учётки
              </DialogTitle>
              <DialogContent>
                <TextField
                  required
                  sx={{ mt: 1 }}
                  defaultValue={cardUser.username}
                  autoFocus
                  label="Почта"
                  type="email"
                  fullWidth
                  variant="standard"
                  name="email"
                />
                <TextField
                  required
                  sx={{ mt: 1 }}
                  defaultValue={cardUser.fullName}
                  label="ФИО"
                  type="text"
                  fullWidth
                  variant="standard"
                  name="fullname"
                />
                <TextField
                  required
                  sx={{ mt: 1, mb: 1 }}
                  defaultValue={cardUser.contract}
                  label="Контракт"
                  type="text"
                  fullWidth
                  variant="standard"
                  name="contract"
                />
                <PositionSelect
                  position={position ? position : cardUser.positionId}
                  setPosition={setPosition}
                />
                <RoleSelect defaultRole={cardUser.role || ""} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEditModal}>Закрыть</Button>
                <Button type="submit">Сохранить</Button>
              </DialogActions>
            </Box>
          </Dialog>
          <Dialog
            sx={{
              "& .MuiDataGrid-virtualScroller": {
                overflowY: "auto !important",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              },
            }}
            maxWidth="xl"
            fullWidth
            open={viewModal}
            onClose={handleCloseViewModal}
            aria-labelledby="user-dialog-title"
            aria-describedby="user-dialog-description"
          >
            <Box
              justifyContent="space-between"
              alignItems="center"
              p={0.5}
              bgcolor="background.paper"
              display="flex"
            >
              <DateRangePicker
                endDate={endDate}
                startDate={startDate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
              />

              <Typography
                bgcolor={"background.paper"}
                textAlign="center"
                variant="h6"
              >
                {cardUser?.fullName || ""}
              </Typography>
              <RecordsHours user={cardUser} records={userRecord.data || []} />
            </Box>
            <Box bgcolor="background.paper" p={0.5}>
              <Table
                loading={userRecord.isFetching}
                user={cardUser}
                data={userRecord.data || []}
              />
            </Box>
          </Dialog>
        </>
      )}
    </Box>
  );
};
