import React, { memo } from "react";
import dayjs, { Dayjs } from "dayjs";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import { ScheduleCollapse } from "./ScheduleCollapse";
import { IAllDepartments } from "entities/schedule/model/schedule";
import { NoRowsOverlay } from "../ScheduleSearch/NoRowsOverlay";

interface ScheduleTableProps {
  endDate: Dayjs | null;
  startDate: Dayjs | null;
  data: Array<IAllDepartments>;
  department: number;
  setDepartment: React.Dispatch<React.SetStateAction<number>>;
}

export const ScheduleTable = memo(
  ({
    endDate,
    startDate,
    data,
    department,
    setDepartment,
  }: ScheduleTableProps) => {
    const handleChange = (
      event: React.SyntheticEvent,
      newValue: React.SetStateAction<string>
    ) => {
      setDepartment(+newValue);
    };

    const isToday = dayjs()
      .utc()
      .add(3, "hour")
      .isBetween(startDate, endDate, "minute");

    return (
      <Box bgcolor={"background.paper"} borderRadius={"5px"}>
        <AppBar
          sx={{ borderRadius: "5px 5px 0px 0px" }}
          color="inherit"
          position="static"
        >
          <Tabs
            value={department}
            onChange={handleChange}
            aria-label="position tabs"
            textColor="inherit"
            variant="fullWidth"
          >
            {data.map((tab, index) => (
              <Tab value={index} label={tab.department} />
            ))}
          </Tabs>
        </AppBar>

        <Stack borderRadius={"5px"} padding={1} marginBottom={1}>
          {data.length > 0 ? (
            data[department].positions
              .filter((row) => row.count)
              .map((row) => (
                <ScheduleCollapse
                  endDate={endDate}
                  startDate={startDate}
                  position={row}
                  isToday={isToday}
                />
              ))
          ) : (
            <Table>
              <NoRowsOverlay
                bottomOvarlayText={"Нет пользователей"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              />
            </Table>
          )}
        </Stack>
      </Box>
    );
  }
);

export default ScheduleTable;
