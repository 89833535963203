import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { IUsersState } from "entities/user/model";
import { usersApi } from "entities/user/api/userService";
import Chip from "@mui/material/Chip";
import { useState } from "react";

export const ScheduleSearch = ({ scheduleUser, setScheduleUser }: any) => {
  const [selectedUsers, setSelectedUsers] = useState<IUsersState[]>([]);
  const { data } = usersApi.useGetAllEnabledUsersQuery();

  const isFullNameRepeated = (data: IUsersState[], fullName: string) => {
    const count = data.filter((option) => option.fullName === fullName).length;
    return count > 1;
  };

  return (
    <Autocomplete
      value={scheduleUser}
      fullWidth
      onChange={(e, value: IUsersState | null) => {
        setScheduleUser(value);
      }}
      disablePortal
      id="schedule-user-select"
      options={data || []}
      renderOption={(props, option) => (
        <li key={option.userId} {...props}>
          {data && isFullNameRepeated(data, option.fullName) ? (
            <Tooltip title={option.fullName}>
              <span key={option.userId}>{option.fullName}</span>
            </Tooltip>
          ) : (
            <span key={option.userId}>{option.fullName}</span>
          )}
        </li>
      )}
      filterOptions={(options, { inputValue }) => {
        return options.filter((option) =>
          option.fullName.toLowerCase().includes(inputValue.toLowerCase())
        );
      }}
      getOptionLabel={(option) => option.username}
      renderInput={(params) => (
        <TextField variant="outlined" {...params} label="Поиск по ФИО" />
      )}
    />
  );
};
