import React, { ReactNode } from "react";
import { memo } from "react";
import dayjs, { Dayjs } from "dayjs";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import GroupIcon from "@mui/icons-material/Group";
import TodayIcon from "@mui/icons-material/Today";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { IUsersOnPosition } from "entities/schedule/model/schedule";
import isBetween from "dayjs/plugin/isBetween"; // не удалять
import { UserTimelineTable } from "./UserTimelineTable";
import { scheduleApi } from "entities/schedule/api/scheduleService";
import { IUsersState } from "entities/user/model";
import { getPeopleWorkingOnDay } from "shared/libs/hooks/getPeopleWorkingOnDay";

interface ScheduleCollapseProps {
  isToday: boolean;
  position: IUsersOnPosition;
  endDate: Dayjs | null;
  startDate: Dayjs | null;
}

export const ScheduleCollapse = memo(
  ({ isToday, position, startDate, endDate }: ScheduleCollapseProps) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [getUsers, users] = scheduleApi.useLazyGetScheduleQuery({
      refetchOnFocus: true,
    });
    const [workingNow, setWorkingNow] = React.useState<number | null>(null);
    const [workingToday, setWorkingToday] = React.useState<number | null>(null);

    const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);

        getUsers(
          {
            positionId: position.id,
            startDate: `${startDate?.format("YYYY-MM-DD")}`,
            endDate: `${dayjs.utc(endDate).add(1, "day").format("YYYY-MM-DD")}`,
          },
          true
        );
      };

    React.useEffect(() => {
      expanded &&
        getUsers(
          {
            positionId: position.id,
            startDate: `${startDate?.format("YYYY-MM-DD")}`,
            endDate: `${dayjs.utc(endDate).add(1, "day").format("YYYY-MM-DD")}`,
          },
          true
        );
    }, [startDate, endDate]);

    return (
      <React.Fragment>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          disableGutters
          sx={{
            border: (theme) => `1px solid ${theme.palette.divider}`,
            "&:not(:last-child)": {
              borderBottom: 0,
            },
            "&:before": {
              display: "none",
            },
            "&.MuiAccordion-root.Mui-expanded": {
              margin: 0,
            },
            boxShadow: "none",
            px: 1,
          }}
          expanded={expanded === `${position.name}`}
          onChange={handleChange(`${position.name}`)}
        >
          <AccordionSummary
            sx={{
              flexDirection: "row-reverse",
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography sx={{ width: "40%", flexShrink: 0, ml: 4 }}>
              {position.name}
            </Typography>
            <Stack
              width={"100%"}
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Chip
                size="small"
                sx={{
                  borderRadius: "6px",
                  width: "130px",
                }}
                label={`Всего: ${position.count}`}
                icon={<GroupIcon />}
              />
              <Chip
                size="small"
                sx={{
                  borderRadius: "6px",
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgb(144, 202, 249, 0.1)"
                      : "rgb(238, 222, 157, 0.2)",
                  width: "130px",
                }}
                label={
                  workingToday && isToday
                    ? `Сегодня: ${workingToday}`
                    : "Нет данных"
                }
                icon={<TodayIcon />}
              />
              <Chip
                size="small"
                sx={{
                  borderRadius: "6px",
                  bgcolor: "rgba(26, 162, 81, 0.15)",
                  width: "130px",
                }}
                label={
                  isToday && workingNow ? `Сейчас: ${workingNow}` : "Нет данных"
                }
                icon={<ScheduleIcon />}
              />
            </Stack>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              p: "0",
              width: "100%",

              overflow: "auto",
              bgcolor: "background.paper",
            }}
          >
            <UserTimelineTable
              endDate={endDate}
              startDate={startDate}
              loading={users.isLoading || users.isUninitialized}
              IsSearchComponent={false}
              users={users.data || null}
              setWorkingNow={setWorkingNow}
              setWorkingToday={setWorkingToday}
            />
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  }
);

export default ScheduleCollapse;
