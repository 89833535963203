import { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { DateRangePicker, Table, TimeTrack } from "shared/ui";
import { TrackSwitch } from "entities/theme/ui";
import dayjs, { Dayjs } from "dayjs";
import { recordsApi } from "entities/record/api/recordService";
import { useAppSelector } from "shared/libs/redux";
import { RecordCreateButton, RecordsHours } from "entities/record/ui";

const Timetrack = () => {
  const variant = useAppSelector((state) => state.theme);
  const user = useAppSelector((state) => state.user);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs.utc().add(-7, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    dayjs.utc().add(7, "day")
  );
  const { data, error, isLoading, isUninitialized, isFetching } =
    recordsApi.useGetRecordsByUserQuery({
      userId: user.userId,
      startDate: `${startDate?.format("YYYY-MM-DD")}`,
      endDate: `${dayjs.utc(endDate).add(1, "day").format("YYYY-MM-DD")}`,
    });
  if (isLoading || isUninitialized) {
    return (
      <div>
        <CircularProgress
          sx={{
            position: "absolute",
            top: "33%",
            left: "50%",
            ml: "-3rem",
            mt: "-3rem",
          }}
          size={"6rem"}
        />
      </div>
    );
  }
  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexFlow: "wrap",
          bgcolor: "background.paper",
          p: 1,
          borderRadius: "5px",
          mb: 1,
          gap: 1,
        }}
      >
        <DateRangePicker
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
        <Box alignItems="center" justifyContent="center" display="flex" gap={2}>
          <TrackSwitch /> <RecordsHours user={user} records={data} />
        </Box>
      </Box>
      {variant.variant === "timetrack" ? (
        <TimeTrack data={data} />
      ) : (
        <Table loading={isFetching} user={user} data={data} />
      )}
      <RecordCreateButton />
    </Box>
  );
};
export default Timetrack;
