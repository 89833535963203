import { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DateRangePicker } from "shared/ui";
import { TrainingsGrid, UsersListTraining } from "widgets";
import { TrainingAccounting } from "entities/training/ui";
import { useGetTrainingsQuery } from "entities/training/api/trainingService";
import { useAppSelector } from "shared/libs/redux";
import dayjs, { Dayjs } from "dayjs";
const Trainings = () => {
  const [value, setValue] = useState(0);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs.utc().add(-1, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    dayjs.utc().add(14, "day")
  );
  const [week, setWeek] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setWeek(event.target.value);
  };
  const user = useAppSelector((state) => state.user);
  const { data, isLoading, isUninitialized, error } = useGetTrainingsQuery({
    userId: user.userId,
    startDate: `${startDate?.format("YYYY-MM-DD")}`,
    endDate: `${dayjs.utc(endDate).add(1, "day").format("YYYY-MM-DD")}`,
    trainingLevel: week,
  });
  if (isLoading || isUninitialized) {
    return (
      <div>
        <CircularProgress
          sx={{
            position: "absolute",
            top: "33%",
            left: "50%",
            ml: "-3rem",
            mt: "-3rem",
          }}
          size={"6rem"}
        />
      </div>
    );
  }
  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          bgcolor: "background.paper",
          borderRadius: "5px",
          mb: 1,
          gap: 1,
          px: 1,
        }}
      >
        <DateRangePicker
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          disableTimeRestrictions={true}
        />
        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Неделя
          </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={week}
            onChange={handleChange}
            label="Неделя"
          >
            {[...Array(13)].map((item, index) => (
              <MenuItem key={index} value={index}>
                {index === 0 ? "всё" : index}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {(user.role === "SUPERVISOR" ||
          user.role === "S4S" ||
          user.role === "MENTOR") && (
          <BottomNavigation
            sx={{ width: "100%", borderRadius: "5px" }}
            showLabels
            value={value}
            onChange={(_, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              sx={{ maxWidth: "none", borderRadius: "5px" }}
              label="Тренинги"
              icon={<FormatListNumberedRtlIcon />}
            />
            <BottomNavigationAction
              sx={{ maxWidth: "none" }}
              label="Ученики"
              icon={<ManageAccountsIcon />}
            />
            <BottomNavigationAction
              sx={{ maxWidth: "none", borderRadius: "5px" }}
              label="Отчётность"
              icon={<FormatListNumberedRtlIcon />}
            />
          </BottomNavigation>
        )}
      </Box>
      {data.length === 0 && (
        <Box p={1} pt={0}>
          Для тебя тренингов пока нет...
        </Box>
      )}
      {value === 0 && <TrainingsGrid trainings={data} role={user.role} />}
      {value === 1 && <UsersListTraining />}
      {value === 2 && (
        <TrainingAccounting startDate={startDate} endDate={endDate} />
      )}
    </Box>
  );
};

export default Trainings;
