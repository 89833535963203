import { Dispatch, SetStateAction } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ListSubheader from "@mui/material/ListSubheader";
import { usersApi } from "entities/user/api/userService";
import { IPositionsState } from "entities/user/model";

type PositionSelectProps = {
  position: number | string;
  setPosition?: Dispatch<SetStateAction<any>>;
  disabled?: Boolean;
};

export const PositionSelect = ({
  position = "",
  setPosition,
  disabled,
}: PositionSelectProps) => {
  const positions = usersApi.useGetAllUsersPositionsQuery();

  const prepareToSelect = (): {
    department: string;
    data: IPositionsState[];
  }[] => {
    const res: { [key: string]: IPositionsState[] } = {};
    // Use a dictionary to store the grouped data
    positions.data?.forEach((item: IPositionsState) => {
      if (res[item.positionDepartment]) {
        res[item.positionDepartment].push(item);
      } else {
        res[item.positionDepartment] = [item];
      }
    });
    // Convert the dictionary to the desired format
    return Object.keys(res).map((key) => ({
      department: key,
      data: res[key],
    }));
  };
  return positions.data ? (
    <FormControl required variant="standard" fullWidth>
      <InputLabel id="position-select-label">Позиция</InputLabel>
      <Select
        disabled={Boolean(disabled)}
        name="position"
        labelId="position-select-label"
        id="position-select"
        value={position}
        label="Позиция"
        onChange={(e) => setPosition && setPosition(e.target.value)}
      >
        {positions.data &&
          prepareToSelect().map((department) => [
            <ListSubheader
              sx={{
                pl: 1,
                bgcolor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "#272626"
                    : "background.default",
              }}
            >
              {department.department}
            </ListSubheader>,
            department.data.map((position) => (
              <MenuItem key={position.id} value={position.id}>
                <Tooltip
                  enterDelay={700}
                  followCursor
                  title={position.positionName}
                >
                  <Typography noWrap textOverflow="ellipsis">
                    {position.positionName}
                  </Typography>
                </Tooltip>
              </MenuItem>
            )),
          ])}
      </Select>
    </FormControl>
  ) : (
    <Skeleton
      sx={{ borderRadius: "5px", mr: "15px" }}
      height={32}
      width={125}
    />
  );
};
