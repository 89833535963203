import dayjs, { Dayjs } from "dayjs";

export const getPeopleWorkingOnDay = (
  date: string | null,
  users: any | null
) => {
  if (users)
    return users?.filter((user: any) => {
      const timelineItem = user?.timeline.find(
        (item: any) =>
          dayjs(item?.date).format("DD/MM/YYYY") ===
          dayjs(date)?.format("DD/MM/YYYY")
      );
      return timelineItem && timelineItem.ranges.length > 0;
    }).length;
};
