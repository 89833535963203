import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import { ITraining } from "entities/training/model";
import { usersApi } from "entities/user/api/userService";
import { IUsersState } from "entities/user/model";
import { useAddUserToTrainingMutation } from "entities/training/api/trainingService";

interface TrainingUserAddProps {
  training: ITraining;
  open: boolean;
  handleClose: () => void;
}

export const TrainingUserAdd = ({
  open,
  handleClose,
  training,
}: TrainingUserAddProps) => {
  const [addUser] = useAddUserToTrainingMutation();
  const [participant, setParticipant] = useState<IUsersState | null>(null);
  const { data } = usersApi.useGetAllEnabledUsersQuery();
  const isFullNameRepeated = (data: IUsersState[], fullName: string) => {
    const count = data.filter((option) => option.fullName === fullName).length;
    return count > 1;
  };
  const handleUserAdd = () => {
    participant &&
      addUser({
        userId: participant.userId,
        trainingId: training.trainingId,
      });
    handleClose();
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="training-edit-dialog-title"
      aria-describedby="training-edit-dialog-description"
    >
      <DialogTitle textAlign="center">
        Добавить ученика в {training.name} от {training.mentor.fullName}
      </DialogTitle>
      <DialogContent sx={{ pb: 0, height: "40vh" }}>
        <Autocomplete
          value={participant}
          onChange={(_, value: IUsersState | null) => {
            setParticipant(value);
          }}
          disablePortal
          id="training-user-add"
          options={data || []}
          renderOption={(props, option) => (
            <li key={option.username} {...props}>
              {data && isFullNameRepeated(data, option.fullName) ? (
                <Tooltip title={option.username}>
                  <span>{option.fullName}</span>
                </Tooltip>
              ) : (
                `${option.fullName}`
              )}
            </li>
          )}
          filterOptions={(options, { inputValue }) => {
            return options.filter((option) =>
              option.fullName.toLowerCase().includes(inputValue.toLowerCase())
            );
          }}
          getOptionLabel={(option) => option.fullName}
          size="small"
          renderInput={(params) => (
            <TextField
              required
              variant="filled"
              sx={{ maxHeight: "50%" }}
              {...params}
              label="Ученик"
            />
          )}
        />
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "flex", sm: "space-between" },
          px: 2,
        }}
      >
        <Button sx={{ width: "130px" }} onClick={handleClose}>
          Закрыть
        </Button>
        <Button color="success" sx={{ width: "130px" }} onClick={handleUserAdd}>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
