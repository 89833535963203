import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Rating from "@mui/material/Rating";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FaceIcon from "@mui/icons-material/Face";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import {
  useDeleteTrainingMutation,
  useEditTrainingMutation,
  useRemoveUserFromTrainingMutation,
} from "entities/training/api/trainingService";
import { ITraining } from "entities/training/model";
import { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
interface TrainingEditProps {
  training: ITraining;
  open: boolean;
  handleClose: () => void;
}

export const TrainingEdit = ({
  open,
  handleClose,
  training,
}: TrainingEditProps) => {
  const [data, setData] = useState(training);
  const [deletedUsers, setDeletedUsers] = useState<Array<string>>([]);
  const [editTraining] = useEditTrainingMutation();
  const [deleteTraining] = useDeleteTrainingMutation();
  const [removeUser] = useRemoveUserFromTrainingMutation();

  useEffect(() => {
    setData(training);
  }, [training]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    deletedUsers.map((item) =>
      removeUser({
        userId: item,
        trainingId: data.trainingId,
      })
    );
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    editTraining({
      ...data,
      name: `${formData.get("trainingName")}`,
      description: `${formData.get("trainingDescription")}`,
      trainingDate: dayjs.utc(`${formData.get("date")}`),
      maxParticipants: +`${formData.get("trainingMaxParticipants")}`,
      linkTelegram: `${formData.get("trainingTgLink")}`,
      mentorId: data.mentor.userId,
      participants: data.participants,
    })
      .then(() => {
        setData({
          ...data,
          name: `${formData.get("trainingName")}`,
          description: `${formData.get("trainingDescription")}`,
          trainingDate: dayjs.utc(`${formData.get("date")}`),
          maxParticipants: +`${formData.get("trainingMaxParticipants")}`,
          linkTelegram: `${formData.get("trainingTgLink")}`,
          mentorId: data.mentor.userId,
          participants: data.participants,
        });
        handleClose();
        setDeletedUsers([]);
      })
      .catch((error) => console.log(error));
  };
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="training-edit-dialog-title"
      aria-describedby="training-edit-dialog-description"
    >
      <DialogTitle sx={{ pb: 0 }} textAlign="center">
        Неделя {training.level} для {training.group}
      </DialogTitle>
      <Box onSubmit={handleSubmit} component="form">
        <DialogContent sx={{ pb: 0 }}>
          <Stack
            justifyContent="space-between"
            sx={{ flexDirection: { sm: "column", md: "row" } }}
          >
            <Box
              flexDirection="column"
              display="flex"
              justifyContent="space-between"
              gap={2}
              sx={{ width: { md: "50%", sm: "100%" } }}
              p={1}
            >
              <TextField
                name="trainingName"
                required
                fullWidth
                id="training-edit-name"
                label="Название"
                maxRows={3}
                multiline
                variant="filled"
                defaultValue={data.name}
              />
              <TextField
                name="trainingDescription"
                fullWidth
                id="training-edit-description"
                label="Описание"
                multiline
                maxRows={5}
                variant="filled"
                defaultValue={data.description}
              />
              <TextField
                name="trainingTgLink"
                required
                type="url"
                fullWidth
                id="training-edit-linkTg"
                label="Ссылка на группу тг"
                variant="filled"
                defaultValue={data.linkTelegram}
              />
              <TextField
                name="trainingMaxParticipants"
                required
                type="number"
                fullWidth
                id="training-create-maxParticipants"
                label="Кол-во участников"
                variant="filled"
                defaultValue={data.maxParticipants}
              />
              <DateTimePicker
                format="YYYY-MM-DD HH:mm"
                slotProps={{ textField: { variant: "filled", name: "date" } }}
                value={dayjs.utc(data.trainingDate)}
                label="Дата тренинга *"
              />
            </Box>
            <Box
              flexDirection="column"
              display="flex"
              gap={2}
              sx={{ width: { md: "50%", sm: "100%" } }}
              p={1}
            >
              {data.participants.map((participant, index) => (
                <Chip
                  color={index >= data.maxParticipants ? "primary" : "default"}
                  onDelete={
                    data.isFinished
                      ? undefined
                      : () => {
                          deletedUsers.includes(participant.userId)
                            ? setDeletedUsers(
                                deletedUsers.filter(
                                  (id) => id !== participant.userId
                                )
                              )
                            : setDeletedUsers([
                                ...deletedUsers,
                                participant.userId,
                              ]);
                        }
                  }
                  sx={{
                    ".MuiChip-label": {
                      mr: data.isFinished ? -4 : 0,
                    },
                    color: deletedUsers.includes(participant.userId)
                      ? "text.disabled"
                      : "none",
                    "& .MuiChip-icon": { position: "absolute", left: 0 },
                    "& .MuiChip-deleteIcon": {
                      position: "absolute",
                      right: 0,
                    },
                  }}
                  key={participant.username}
                  clickable
                  icon={
                    data.isFinished ? (
                      <Checkbox
                        onChange={() => {
                          setData({
                            ...data,
                            participants: data.participants.map((item, i) => {
                              return {
                                ...item,
                                feedback:
                                  i === index ? !item.feedback : item.feedback,
                              };
                            }),
                          });
                        }}
                        checked={participant.feedback}
                        name="feedback"
                        sx={{ padding: "4px" }}
                        icon={<FaceIcon color="inherit" />}
                        checkedIcon={<FaceIcon color="success" />}
                      />
                    ) : (
                      <FaceIcon color="inherit" />
                    )
                  }
                  label={
                    data.isFinished ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Box
                          onClick={() =>
                            participant.tgLink &&
                            window.open(
                              `https://t.me/${participant.tgLink.replace(
                                "@",
                                ""
                              )}`,
                              "_blank"
                            )
                          }
                          overflow="hidden"
                          display="inline-block"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          sx={{ width: { xs: "70px", sm: "auto" } }}
                          left={45}
                          position="absolute"
                        >
                          {participant.fullName}
                        </Box>
                        <Rating
                          onChange={(_, newValue) => {
                            setData({
                              ...data,
                              participants: data.participants.map((item, i) => {
                                return {
                                  ...item,
                                  score:
                                    i === index
                                      ? newValue
                                        ? newValue * 2
                                        : 0
                                      : item.score,
                                };
                              }),
                            });
                          }}
                          name="score"
                          value={participant.score / 2}
                          precision={0.5}
                          sx={{ ml: 2, position: "absolute", right: 10 }}
                        />
                      </Box>
                    ) : (
                      <Box
                        onClick={() =>
                          participant.tgLink &&
                          window.open(
                            `https://t.me/${participant.tgLink.replace(
                              "@",
                              ""
                            )}`,
                            "_blank"
                          )
                        }
                        overflow="hidden"
                        display="inline-block"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        sx={{
                          width: { xs: "140px", mobile: "auto" },
                          textDecoration: deletedUsers.includes(
                            participant.userId
                          )
                            ? "line-through"
                            : "none",
                        }}
                        left={45}
                      >
                        {participant.fullName}
                      </Box>
                    )
                  }
                />
              ))}
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "flex", sm: "space-between" },
            px: 2,
          }}
        >
          <Box
            display="flex"
            sx={{
              width: { xs: "100%", sm: "auto" },
              justifyContent: "space-between",
            }}
            gap={1}
          >
            <Button
              sx={{ width: "130px" }}
              onClick={() => {
                deleteTraining(data);
                handleClose();
              }}
              color="error"
            >
              Удалить
            </Button>
            <Button
              sx={{ width: "130px" }}
              onClick={() => {
                editTraining({
                  ...data,
                  isFinished: !data.isFinished,
                });
                setDeletedUsers([]);
                handleClose();
              }}
              color={data.isFinished ? "warning" : "success"}
            >
              {data.isFinished ? "Возобновить" : "Завершить"}
            </Button>
          </Box>
          <Box
            display="flex"
            sx={{
              width: { xs: "100%", sm: "auto" },
              justifyContent: "space-between",
            }}
            gap={1}
          >
            <Button sx={{ width: "130px" }} onClick={handleClose}>
              Закрыть
            </Button>
            <Button sx={{ width: "130px" }} type="submit">
              Сохранить
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
