import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { ITimesheetRecord } from "entities/record/model";
import { usersApi } from "entities/user/api/userService";
import { IPositionsState, IUsersState } from "entities/user/model";
export const RecordsHours = ({
  records,
  user,
}: {
  records: Array<ITimesheetRecord>;
  user: IUsersState;
}) => {
  const positions = usersApi.useGetAllUsersPositionsQuery();

  function getHoursWithCoefficient(record: ITimesheetRecord): number {
    const recordPosition: IPositionsState = positions.data?.find(
      (position) => position.id === record.positionId
    ) as IPositionsState;
    const userPosition: IPositionsState = positions.data?.find(
      (position) => position.id === user.positionId
    ) as IPositionsState;
    return (
      (recordPosition?.positionRate / userPosition?.positionRate) * record.hours
    );
  }
  return (
    <Box flexWrap="wrap" display="flex" gap={1}>
      {positions.data && (
        <Stack
          height={56}
          borderRadius="5px"
          p={1}
          gap={1}
          direction="row"
          bgcolor={(theme) =>
            theme.palette.mode === "dark" ? "#404040" : "background.default"
          }
        >
          <Tooltip title="Всего часов с коэффициентом">
            <Box
              sx={{ cursor: "pointer" }}
              p={1}
              borderRadius="5px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bgcolor={"background.paper"}
            >
              <Typography
                lineHeight={1}
                textAlign="center"
                fontWeight={900}
                variant="h5"
              >
                {records
                  .reduce(
                    (sum, records) =>
                      records.hours
                        ? records.is15x
                          ? sum + getHoursWithCoefficient(records) * 1.5
                          : records.is20x
                          ? sum + getHoursWithCoefficient(records) * 2
                          : sum + getHoursWithCoefficient(records)
                        : sum,
                    0
                  )
                  .toFixed(2)}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Подтверждено с коэффициентом">
            <Box
              color="#73db6ad8"
              sx={{ cursor: "pointer" }}
              p={1}
              borderRadius="5px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bgcolor={"background.paper"}
            >
              <Typography
                lineHeight={1}
                textAlign="center"
                fontWeight={900}
                variant="h5"
              >
                {records
                  .reduce(
                    (sum, records) =>
                      records.hours && records.isConfirmed
                        ? records.is15x
                          ? sum + getHoursWithCoefficient(records) * 1.5
                          : records.is20x
                          ? sum + getHoursWithCoefficient(records) * 2
                          : sum + getHoursWithCoefficient(records)
                        : sum,
                    0
                  )
                  .toFixed(2)}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Не подтверждено с коэффициентом">
            <Box
              color="#DBAE6A"
              sx={{ cursor: "pointer" }}
              p={1}
              borderRadius="5px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bgcolor={"background.paper"}
            >
              <Typography
                lineHeight={1}
                textAlign="center"
                fontWeight={900}
                variant="h5"
              >
                {records
                  .reduce(
                    (sum, records) =>
                      records.hours && !records.isConfirmed
                        ? records.is15x
                          ? sum + getHoursWithCoefficient(records) * 1.5
                          : records.is20x
                          ? sum + getHoursWithCoefficient(records) * 2
                          : sum + getHoursWithCoefficient(records)
                        : sum,
                    0
                  )
                  .toFixed(2)}
              </Typography>
            </Box>
          </Tooltip>
        </Stack>
      )}
      {/* <Stack
        height={56}
        borderRadius="5px"
        p={1}
        gap={1}
        direction="row"
        bgcolor={(theme) =>
          theme.palette.mode === "dark" ? "#404040" : "background.default"
        }
      >
        <Tooltip title="Всего часов">
          <Box
            sx={{ cursor: "pointer" }}
            p={1}
            borderRadius="5px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={"background.paper"}
          >
            <Typography
              lineHeight={1}
              textAlign="center"
              fontWeight={900}
              variant="h5"
            >
              {records
                .reduce(
                  (sum, records) => (records.hours ? sum + records.hours : sum),
                  0
                )
                .toFixed(2)}
            </Typography>
          </Box>
        </Tooltip>
        <Tooltip title="Подтверждено">
          <Box
            color="#73db6ad8"
            sx={{ cursor: "pointer" }}
            p={1}
            borderRadius="5px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={"background.paper"}
          >
            <Typography
              lineHeight={1}
              textAlign="center"
              fontWeight={900}
              variant="h5"
            >
              {records
                .reduce(
                  (sum, records) =>
                    records.hours && records.isConfirmed
                      ? sum + records.hours
                      : sum,
                  0
                )
                .toFixed(2)}
            </Typography>
          </Box>
        </Tooltip>
        <Tooltip title="Не подтверждено">
          <Box
            color="#DBAE6A"
            sx={{ cursor: "pointer" }}
            p={1}
            borderRadius="5px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={"background.paper"}
          >
            <Typography
              lineHeight={1}
              textAlign="center"
              fontWeight={900}
              variant="h5"
            >
              {records
                .reduce(
                  (sum, records) =>
                    records.hours && !records.isConfirmed
                      ? sum + records.hours
                      : sum,
                  0
                )
                .toFixed(2)}
            </Typography>
          </Box>
        </Tooltip>
      </Stack> */}
    </Box>
  );
};
